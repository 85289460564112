<template>
  <div class="tw-relative mobile-package-story">
    <div class="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full">
      <div v-if="experience" class="tw-relative">
        <Story
          style="margin-top: -4px"
          :isMobile="true"
          :showButtons="false"
          :items="storyItems"
          :storyId="experience.exp_id"
          :expandStoryCarouselEnabled="false"
          :influencer="experience.influencer_data"
          @toggle-expand="() => {}"
        />
      </div>
    </div>
    <div class="tw-absolute tw-top-16 tw-right-4">
      <button @click="exitView" class="exit-button">
        <Exit class="tw-text-white tw-w-5 tw-h-5" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import Story from '@/views/mobilePages/Stories/StoryView.vue';
import Story from '@/views/inspiration/insta-story/InstaStory.vue';
import Exit from '@/assets/destinationPage/icons/exit.svg';

export default {
  name: 'MobileStory',
  components: {
    Story,
    Exit,
  },
  computed: {
    ...mapGetters([ 'experience' ]),
    storyItems() {
      return this.experience.story_array.map(item => {
        const isVideo = item.includes('.mov?') || item.includes('.mp4?');
        return {
          url: item,
          type: isVideo ? 'video' : 'image',
        };
      });
    },
  },
  methods: {
    ...mapActions([ 'loadExperience' ]),
    exitView() {
      this.$router.go(-1);
    },
  },
  async mounted() {
    try {
      await this.loadExperience(this.$route.params.inspirationId);
    } catch (e) {
      console.log(e);
      this.$router.push({ name: 'LandingPage' });
    }
  },
};
</script>

<style lang="scss" scoped>
.exit-button {
  @apply tw-bg-gray-300  tw-rounded-full tw-p-2;
}
</style>
